@import "../../../styles/variables";
@import "../../../styles/mixins";

.solution-two {
  margin: 0 22%;
  margin-bottom: 5rem;
  margin-top: 20rem;
  color: $color-black;

  @include respond(laptop-small) {
    margin: 10rem 5%;
  }
  &__top {
    margin: 5rem 0;
    display: flex;
    align-items: center;
    &--left {
      margin-left: -10%;

      @include respond(laptop-small) {
        margin-left: unset;
        text-align: center;
      }

      & svg {
        width: 5rem;

        @include respond(laptop-small) {
          display: none;
        }
      }
    }
  }

  &__title {
    margin-bottom: 1rem;
    margin-left: 5rem;

    font-size: 5rem;
    font-weight: normal;
    text-align: center;
    color: #333;

    @include respond(laptop-small) {
      text-align: center;
      width: 100%;
      margin-top: 2rem;
      // margin-left: unset;
    }
  }

  &__information {
    margin-bottom: 5rem;
    &--desc {
      margin-bottom: 2rem;

      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.2rem;
      letter-spacing: 0.02em;
      color: #333;
      @include respond(laptop-small) {
        text-align: center;
      }
    }
  }
}

// button stylings

.r-link {
  display: var(--rLinkDisplay, inline-flex) !important;
}

.r-link[href] {
  color: var(--rLinkColor) !important;
  text-decoration: var(--rLinkTextDecoration, none) !important;
}

.link {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.link:hover {
}

.link {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
  outline: 2px solid lightblue;
  outline-offset: 0.5rem;
}
