@import "../../../styles/variables";
@import "../../../styles/mixins";

.home-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 20%;

  @include respond(tablet-large) {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
  }

  &__line {
    @include respond(tablet-large) {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;

    @include respond(tablet-large) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__left {
    & img {
      width: 30rem;
      height: 30rem;
      object-fit: cover;
      border-radius: 50%;

      @include respond(tablet-large) {
        height: 20rem;
        width: 20rem;
        margin-bottom: 2rem;
      }
    }
  }

  &__right {
    margin-left: 5rem;
    width: 65rem;

    @include respond(tablet-large) {
      margin: 0 10%;
      width: unset;
    }
    &--info {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.5rem;
      letter-spacing: 0.02em;
      text-align: left;
      margin-bottom: 1rem;
      color: $color-black;
    }
    &--title {
      font-size: 3.2rem;
      font-weight: normal;
      line-height: 0.88;
      letter-spacing: 0em;
      text-align: left;
      color: $color-lightblue;

      @include respond(tablet-large) {
        font-size: 2.5rem;
      }
    }

    &--desc {
      text-align: left;
      margin: 3rem 0;
      color: $color-black;

      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.2rem;
      letter-spacing: 0.02em;
    }

    &--btn {
      border: none;
      outline: none;

      @include respond(laptop) {
        margin-bottom: 2rem;
      }

      & svg {
        @include respond(laptop) {
          width: 20rem;
        }
      }

      @include respond(tablet-large) {
        width: 10rem;
      }
    }
  }

  &__bottom {
    font-size: 5rem;
    font-weight: normal;
    color: $color-black;
    align-self: center;
    margin-top: 10rem;

    @include respond(tablet-large) {
      margin-left: unset;
    }
  }
}
