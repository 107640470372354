@import "../../../styles/variables";
@import "../../../styles/mixins";

.focus-three {
  margin: 0 20%;
  margin-bottom: 5rem;
  color: $color-black;

  @include respond(mobile-medium) {
    margin: 0 10%;
  }
  &__top {
    margin: 5rem 0;
    display: flex;
    align-items: center;
    &--left {
      margin-left: -10%;

      & svg {
        width: 5rem;
      }

      @include respond(laptop-small) {
        display: none;
      }
    }
  }

  &__title {
    font-size: 2.5rem;
    font-family: Sofia Pro !important;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-left: 5rem;
    color: #333;

    @include respond(laptop-small) {
      text-align: center;
    }
  }

  &__information {
    margin-bottom: 3rem;
    &--info {
      font-size: 2.5rem;
      font-weight: 700;
      letter-spacing: 0.02em;
      font-family: Sofia Pro !important;
      @include respond(laptop-small) {
        text-align: center;
      }
    }
    &--desc {

      margin: 3rem 0;
      color: #333;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.2rem;
      letter-spacing: 0.02em;
      @include respond(laptop-small) {
        text-align: center;
      }
    }
  }
}
