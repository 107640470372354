@import "../../../styles/variables";
@import "../../../styles/mixins";

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50rem;

  margin: 4rem 20%;

  @include respond(laptop-large) {
    margin: 2rem 10%;
  }

  @include respond(laptop) {
    margin: 0rem 0%;
  }

  &__title {
    font-size: 4rem;
    font-weight: 900;
    line-height: 7.8rem;
    letter-spacing: 0.02em;
    color: $color-black;
  }

  &__desc {
    margin: 3rem 20%;

    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.2rem;
    letter-spacing: 0.02em;
    color: #333;

    @include respond(laptop-large) {
      text-align: center;
    }

    @include respond(tablet) {
      margin: 3rem 5%;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;

    @include respond(laptop-large) {
      flex-direction: column;
    }
    @include respond(tablet) {
      align-items: unset;
    }
  }

  &__leftWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    @include respond(laptop-large) {
      text-align: center;
    }
  }
  &__left {
    margin-right: 4rem;
    margin-top: -4rem;
    @include respond(laptop-large) {
      margin-top: 0;
    }

    @include respond(tablet) {
      margin-right: 0;
      align-self: center;
    }

    &--left {
      width: 22rem;
      & h3 {
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 2.9rem;
        letter-spacing: 0.02em;
        color: $color-black;
      }
      & p {
        font-size: 2.1rem;
        font-weight: 400;
        line-height: 2.8rem;
        letter-spacing: 0.02em;
        color: gray;
      }
    }
    &--right {
      margin-left: 2rem;
    }
  }

  &__right {
    flex: 0 0 100%;
    &--fieldController {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 3.5rem;
      background-color: #f6ffee;

      @include respond(tablet) {
        width: 100%;
        flex-direction: column;
      }

      @include respond(tablet) {
        background-color: white;
      }

      & input[type="text"],
      input[type="email"],
      textarea {
        font-size: 2.1rem;
        font-weight: 500;
        line-height: 2.8rem;
        letter-spacing: 0.02em;
        padding: 2rem 2rem 1rem 0;
        width: 100%;
        border: none;
        outline: none;
        border-bottom: 1px solid gray;
        font-family: inherit;
        background: #f6ffee;

        @include respond(tablet) {
          background: white;
        }

        &:not(:last-of-type) {
          margin-right: 2rem;

          @include respond(tablet) {
            margin-right: 0rem;
          }
        }
      }

      & input:-webkit-autofill,
      & input:-webkit-autofill:hover,
      & input:-webkit-autofill:focus,
      &input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #f6ffee inset !important;

        @include respond(laptop-large) {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }
    }
    &--bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include respond(tablet) {
        flex-direction: column;
      }

      & input[type="checkbox"],
      textarea {
        width: 2rem;
        height: 2rem;
      }

      & p {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
        letter-spacing: 0.02em;

        @include respond(tablet) {
          margin: 2rem 0;
        }
      }

      & button {
        border: none;
        outline: none;
        padding: 1rem 5rem;
        border-radius: 0.6rem;
        color: white;
        font-size: 1.6rem;
        font-weight: bold;
        background: rgb(120, 181, 33);
        cursor: pointer;
      }
    }
  }
}

.contact__back-circleWrapper {
  position: relative;
  height: 73rem;
  overflow-y: hidden;
  overflow-x: hidden;

  @include respond(tablet) {
    height: 80rem;
  }
}

.contact__back-circle {
  width: 100vw;
  height: 100vw;
  background-color: #f6ffee;
  border-radius: 50%;
  position: absolute;
  z-index: -100;

  @include respond(tablet) {
    display: none;
  }
}

.home-footer {
  font-size: 1.8rem;
  margin-top: 1rem;
  text-align: center;
}

.lineIcon {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  @include respond(tablet) {
    width: 100%;
  }
}
