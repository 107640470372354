@import "../../../styles/variables";
@import "../../../styles/mixins";

.solution {
  margin: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__left,
  &__right {
    & svg {
      width: 2.5rem;
      cursor: pointer;
    }

    @include respond(laptop) {
      display: none;
    }
  }

  &__card-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
  }

  &__down {
    margin: 2rem 0;

    & svg {
      width: 2.5rem;
    }
  }

  &__card-wrapper {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;

    @include respond(laptop) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
    }
  }

  &__overlay--wrapper {
    position: relative;
    height: 40rem;
    cursor: pointer;
    text-decoration: none;
    color: inherit;

    @include respond(laptop) {
      margin-bottom: 4rem;
    }
  }

  &__overlay {
    width: 100%;
    height: 20rem;
    font-size: 2.3rem;
    font-weight: 700;
    background-color: rgb(192, 255, 255);
    border-radius: 0 0 2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transition-duration: 0.5s;
    text-decoration: none;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    width: 28.5rem;
    & img {
      width: 100%;
      height: 22rem;
      object-fit: cover;
      border-radius: 2rem;
    }
  }

  &__title {
    font-size: 5rem;
    margin-bottom: 2rem;
    font-weight: normal;
    text-align: center;
    color: #333;
  }

  &__desc {
    font-family: Sofia Pro;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 3.1rem;
    letter-spacing: 0.02em;
    text-align: center;
    margin: 5rem 25%;

    @include respond(laptop-large) {
      margin: 5rem 15%;
    }

    @include respond(laptop) {
      margin: 5rem 8%;
    }
  }
}

.solution__overlay--wrapper:hover .solution__overlay {
  top: 0;
  opacity: 0.8;
  height: 22rem;
  border-radius: 2rem;
}

.solution__back-circleWrapper {
  position: relative;
  overflow-y: hidden;

  @include respond(laptop) {
    height: unset;
  }
}

.solution__back-circle {
  height: 120rem;
  width: 120rem;
  background-color: #f6ffee;
  border-radius: 50%;
  position: absolute;
  z-index: -100;
  @include respond(laptop) {
    display: none;
  }
}
