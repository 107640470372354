.social {
  width: 80%;
  margin: 4rem auto;
  &__list {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    &--item {
      list-style: none;
      &:not(:last-child) {
        margin-right: 4rem;
      }
    }

    &--icon {
      width: 4rem;
      height: 4rem;
      margin-bottom: 2rem;
      fill: var(--color-primary);
      &:hover {
        fill: var(--color-primary-2);
      }
    }

    &--link {
      display: flex;
      flex-direction: column;
      text-decoration: none;
    }

    &--text {
      font-size: 1.4rem;
      font-weight: 300;
      color: var(--color-front);
    }
  }
}

.fill-primary {
  color: #077da2;
}
