@import "../../../styles/variables";
@import "../../../styles/mixins";

.solution-one {
  background-repeat: no-repeat;
  background-size: cover;
  height: 80rem;
  background-position: center;
  position: relative;
  z-index: -1;

  &__img {
    position: absolute;
    bottom: -14.6rem;
    display: flex;
    justify-content: center;
    width: 100vw;

    // @include respond(laptop-small) {
    //   display: none;
    // }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    width: 25rem;
    background-color: lightblue;
    border-radius: 2rem;
    & img {
      width: 100%;
      height: 20rem;
      width: 100%;
      object-fit: contain;
      background-color: white;
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
      // border-radius: 3rem;
    }

    & p {
      align-self: stretch;
      margin-top: -2rem;
      background-color: lightblue;
      padding: 5rem 0;
      text-align: center;
      color: $color-black;
      font-size: 2rem;
      font-weight: 700;
      border-bottom-right-radius: 5rem;
      border-bottom-left-radius: 5rem;
    }
  }
}

.dfoot {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}
