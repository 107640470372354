@import "../../../styles/variables";
@import "../../../styles/mixins";

.focus-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10rem 20%;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include respond(laptop-small) {
      flex-direction: column;
    }
  }

  &__left {
    & img {
      width: 30rem;
      height: 30rem;
      object-fit: cover;
      border-radius: 50%;

      @include respond(tablet-large) {
        height: 20rem;
        width: 20rem;
        margin-bottom: 2rem;
      }
    }
  }

  &__right {
    flex: 0 0 60%;
    @include respond(laptop-small) {
      margin-top: 2rem;
    }
    & p {
      text-decoration: underline;

      color: #333;
      font-size: 2rem;
      font-weight: 500;
      line-height: 3rem;
      letter-spacing: 0.02em;

      @include respond(laptop-small) {
        text-align: center;
      }
    }
  }
}
