@import "../../../styles/variables";
@import "../../../styles/mixins";

.home-one {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/malezi-grand-central.appspot.com/o/Malezi%20Hero%20Image.jpg?alt=media&token=3bcf387e-567c-4413-9bd2-c61121edbd04");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 70rem;
  background-position: center;
  position: relative;

  @include respond(tablet-large) {
    height: 50rem;
  }

  &__title {
    font-size: 6.5em;
    font-style: normal;
    font-weight: bolder;
    line-height: 0.9;
    letter-spacing: 2.5px;
    color: $color-lightblue;
    text-align: center;
    padding-top: 5rem;

    @include respond(tablet-large) {
      font-size: 4rem;
    }
  }

  &__desc {
    font-family: Sofia Pro;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 7rem;
    letter-spacing: 0.02em;
    color: $color-lightblue;
    text-align: center;
  }

  &__bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include respond(tablet-small) {
      flex-direction: column;
    }

    &--left {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: orangered;
      height: 10rem;
      flex: 0 0 50%;
      img {
        width: 7rem;
      }

      @include respond(tablet-small) {
        width: 100%;
      }

      & h3 {
        color: white;
        font-size: 3rem;
        font-family: "Sofia Pro";
        @include respond(tablet-small) {
          padding: 1rem 0;
        }
      }
    }

    &--right {
      background-color: green;
      flex: 1;
      height: 10rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include respond(tablet-small) {
        width: 100%;
      }

      & h3 {
        color: white;
        font-size: 3rem;
        font-family: "Sofia Pro";

        @include respond(tablet-small) {
          padding: 1rem 0;
        }
      }
    }
  }
}
