@import "./variables";
@import "./mixins.scss";

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 62.5%; // 1rem = 10px
  overflow-x: hidden;
  @include respond(tablet-large) {
    font-size: 56.25%;
  }

  @include respond(tablet) {
    font-size: 50%;
  }
  @include respond(mobile-medium) {
    font-size: 48%;
  }

  box-sizing: border-box;
}

body {
  margin: 0%;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  font-family: Sofia Pro;
  color: $color-black;
}

h1,
h2,
h3 {
  font-family: din;
}

.section-divider {
  margin-top: 8rem;
}
