@import "../../../styles/variables";
@import "../../../styles/mixins";

.home-fourWrapper {
  display: flex;
  justify-content: center;
  margin: 4rem 20%;

  @include respond(laptop) {
    flex-direction: column;
    align-items: center;

    margin: 5rem 10% 15rem 10%;
  }
}
.home-four {
  width: 70%;
  min-width: 120rem;
  max-width: 130rem;

  @include respond(laptop) {
    width: unset;
    max-width: unset;
    min-width: unset;
  }

  &--images {
    position: relative;
    display: flex;
    padding-top: 5rem;
    &__left {
      position: absolute;
      bottom: 0;
      z-index: 100;
      left: -4rem;

      @include respond(laptop) {
        display: none;
      }
    }
    &__right {
      @include respond(laptop) {
        width: 80vw;
        display: flex;
        justify-content: center;
      }
      .disapper {
        @include respond(laptop) {
          display: none;
        }
      }

      & svg {
        @include respond(laptop) {
          min-height: 20rem;
        }
      }
    }
  }
  &--infoOne {
    color: $color-green;
    margin-left: 50%;

    font-size: 7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.88;

    @include respond(laptop) {
      margin-left: unset;
      font-size: 4rem;
      text-align: center;
    }
  }
  &--title {
    color: $color-black;
    margin: 1.5rem 0 1.5rem 50%;

    font-size: 3.5rem;
    font-weight: 400;
    line-height: 1.1;

    @include respond(laptop) {
      margin-left: unset;
      font-size: 2rem;
      text-align: center;
    }
  }
  &--infoTwo {
    color: $color-black;
    margin-left: 50%;

    font-size: 4rem;
    font-weight: 400;
    line-height: 1.1;

    @include respond(laptop) {
      margin-left: unset;
      text-align: center;
      margin: 1rem 0;
    }
  }
  &--descOne {
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2.3rem;
    letter-spacing: 0.02em;
    color: $color-green;
    margin-left: 35rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include respond(laptop) {
      margin-left: unset;
      text-align: center;
    }
  }
  &--descTwo {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.2rem;
    letter-spacing: 0.02em;
    margin-left: 50%;

    @include respond(laptop) {
      margin-left: unset;
      text-align: center;
    }
  }
  &--btn {
    outline: none;
    border: none;

    @include respond(laptop) {
      margin-bottom: 2rem;
    }

    & svg {
      @include respond(laptop) {
        width: 20rem;
      }
    }
  }
  &--btnWrapper {
    display: flex;
    align-items: center;
    margin-left: calc(50% - 35rem);

    @include respond(laptop) {
      flex-direction: column;
      margin-left: unset;
      text-align: center;
    }
  }
}

.home-four__back-circleWrapper {
  position: relative;
  width: 100%;
  @include respond(tablet) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.home-four__back-circle {
  height: 80rem;
  width: 80rem;
  background-color: #f6ffee;
  border-radius: 50%;
  position: absolute;
  left: 30%;
  z-index: -100;
  @include respond(laptop) {
    // left: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 8rem;
    height: 60rem;
    width: 60rem;
  }
  @include respond(tablet) {
    // left: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 30rem;
    height: 70rem;
    width: 70rem;
    overflow: hidden;
  }
}

.interchange__four {
  &--large {
    & img {
      width: 99%;
      margin-left: 1rem;
      object-fit: cover;
      @include respond(laptop) {
        width: 100vw;
        margin-left: 0rem;
      }

      @include respond(tablet-large) {
        height: 45rem;
        align-self: center;
      }
      @include respond(tablet-small) {
        height: 25rem;
      }
      @include respond(mobile-medium) {
        height: 20rem;
      }
    }

    @include respond(tablet-large) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
