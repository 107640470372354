@import "../../../styles/variables";
@import "../../../styles/mixins";

.home-five {
  margin: 15rem 20%;

  @include respond(laptop-large) {
    margin: 2rem 10%;
  }

  @include respond(laptop) {
    margin: 0rem 0%;
  }

  &__line {
    display: flex;
    justify-content: center;
  }

  &__card-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respond(tablet-large) {
      justify-content: center;
    }
  }

  &__overlay--wrapper {
    position: relative;
    height: 40rem;
    cursor: pointer;
    text-decoration: none;
    color: inherit;

    @include respond(laptop) {
      margin-bottom: 3rem;
    }
  }

  &__overlay {
    width: 100%;
    height: 20rem;
    font-size: 2.3rem;
    font-weight: 700;
    background-color: rgb(192, 255, 255);
    border-radius: 0 0 2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transition-duration: 0.5s;
    text-decoration: none;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    width: 28.5rem;
    & img {
      width: 100%;
      height: 22rem;
      object-fit: cover;
      border-radius: 2rem;
    }
  }

  &__title {
    text-align: center;
    font-size: 5rem;
    font-weight: normal;
    text-align: center;
    color: #333;
  }

  &__desc {
    font-style: normal;
    text-align: center;
    margin: 5rem 20%;

    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.2rem;
    letter-spacing: 0.02em;
    color: #333;

    @include respond(laptop-large) {
      margin: 5rem 15%;
    }

    @include respond(laptop) {
      margin: 5rem 8%;
    }
  }
}

.home-five__overlay--wrapper:hover .home-five__overlay {
  top: 0;
  opacity: 0.8;
  height: 22rem;
  border-radius: 2rem;
}

.home-five__overlay {
  width: 28.6rem;
}

.home-five__overlay--wrapper {
  flex: 30%;
  flex-grow: 0;
  margin-bottom: 3rem;

  @include respond(tablet-large) {
    flex: 45%;
    flex-grow: 0;
  }
}

.home-five__overlay--wrapper:hover .home-five__card > p {
  display: none;
}
