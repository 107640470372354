@import "../../../styles/variables";
@import "../../../styles/mixins";

.home-threeWrapper {
  display: flex;
  justify-content: center;
  margin: 16rem 20% 30rem 20%;

  @include respond(laptop) {
    flex-direction: column;
    align-items: center;

    margin: 5rem 10% 15rem 10%;
  }
}
.home-three {
  width: 70%;
  max-width: 130rem;
  min-width: 120rem;
  @include respond(laptop) {
    width: unset;
    max-width: unset;
    min-width: unset;
  }

  &--images {
    position: relative;
    padding-top: 5rem;
    &__left {
      @include respond(laptop) {
        width: 100%;
      }
      .disapper {
        @include respond(laptop) {
          display: none;
        }
      }

      & svg {
        @include respond(laptop) {
          width: 100%;
          min-height: 20rem;
        }
      }
    }
    &__right {
      position: absolute;
      bottom: 0;
      right: 0;

      @include respond(laptop) {
        display: none;
      }
    }
  }
  &--infoOne {
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 1.1;
    width: 50%;
    padding-left: 10rem;
    color: $color-lightblue;

    @include respond(laptop) {
      width: unset;
      font-size: 2rem;
      text-align: center;
      padding-left: unset;
    }
  }
  &--title {
    font-size: 7rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.88;
    text-align: left;
    padding: 1.5rem 0;
    width: 50%;
    padding-left: 10rem;
    color: $color-black;

    @include respond(laptop) {
      width: unset;
      font-size: 4rem;
      text-align: center;
      padding-left: unset;
    }
  }
  &--infoTwo {
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.1;
    width: 50%;
    padding-left: 10rem;
    color: $color-black;

    @include respond(laptop) {
      width: unset;
      text-align: center;
      padding-left: unset;
    }
  }
  &--descOne {
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2.3rem;
    letter-spacing: 0.02em;
    width: 50%;
    padding-left: 10rem;
    color: $color-lightblue;
    margin: 2rem 0;
    margin-right: 5rem;

    @include respond(laptop) {
      width: unset;
      text-align: center;
      padding-left: unset;
    }
  }
  &--descTwo {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 2.2rem;
    letter-spacing: 0.02em;
    width: 50%;
    padding-left: 10rem;
    color: $color-black;
    padding-bottom: 2rem;

    @include respond(laptop) {
      width: unset;
      text-align: center;
      padding-left: unset;
    }
  }
  &--btn {
    outline: none;
    border: none;

    @include respond(laptop) {
      margin-bottom: 2rem;
    }

    & svg {
      @include respond(laptop) {
        width: 20rem;
      }
    }
  }
  &--btnWrapper {
    display: flex;
    align-items: center;

    @include respond(laptop) {
      flex-direction: column;
    }
  }
}

.home-three__back-circleWrapper {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.home-three__back-circle {
  height: 80rem;
  width: 80rem;
  background-color: #eaffff;
  border-radius: 50%;
  position: absolute;
  left: 30%;
  z-index: -100;
  top: 15rem;
  @include respond(laptop) {
    left: 20%;
    height: 60rem;
    width: 60rem;
  }
  @include respond(tablet) {
    left: 10rem;
    top: 30rem;
    height: 70rem;
    width: 70rem;
  }
}

.interchange__three {
  &--large {
    & img {
      width: 99%;
      object-fit: cover;

      @include respond(laptop) {
        width: 100vw;
      }

      @include respond(tablet-large) {
        height: 45rem;
        align-self: center;
      }
    }

    @include respond(tablet-large) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
